import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Layout } from "../layouts/Site"
import { Authors, ShareButtons, Tags, Metadata } from "../components/Article"
import { slug } from "../gatsby/articles.create"
import { RichText } from "../components/RichText"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import { faShareAlt } from "@fortawesome/free-solid-svg-icons"
import { PageWidth, Section } from "../components/Containers"
import { Image } from "../components/Image"
import { PageHeading } from "../components/Headings"
import { join, sectionEndLine } from "../styles/styles"

export default function ArticleTemplate({ data }) {
  const { site, article, defaultImage, defaultMetadata } = data
  article.image = article.image || defaultImage
  article.url = `${site.siteMetadata.url}${slug("articles", article.key)}`

  const author = Array.isArray(article.author) ? article.author[0].name : article.author.name

  return <Layout title={`Xodiac musings - ${article.name}`}>
    <Helmet>
      <meta name='description' content={article.metaDescription || defaultMetadata.metaDescription} />
      {article.tags && <meta name='keywords' content={join(article.tags.map(t => t.description || t.name))} />}
      <meta property='og:title' content={article.name} />
      <meta property='og:url' content={article.url} />
      <meta property='og:type' content='article' />
      {article.image.fixed && <meta property='og:image' content={`http:${article.image.fixed.src}`} />}
      <meta property='og:description' content={`Musings by ${author}`} />
      <meta name='author' content={author} />
      <meta name='publish_date' property='og:publish_date' content={article.publishDate} />
      <meta name='twitter:label1' content='Reading time' />
      <meta name='twitter:data1' content={`${article.fields.readingTimeInMinutes} min`} />
    </Helmet>
    <PageWidth className={join(sectionEndLine, 'pt-header md:pt-header-md')}>
      <Section cols='auto' className='pt-4'>
        <ArticleHeader article={article} />
        <Image image={article.image} />
      </Section>
    </PageWidth>
    <PageWidth>
      <Section>
        <RichText body={article.body} className='max-w-prose' />
        <div className='mt-2'>
          <Icon icon={faShareAlt} className='text-primary mr-4' />
          <ShareButtons article={article} />
        </div>
        <Tags className='pt-4'>{article.tags}</Tags>
      </Section>
    </PageWidth>
  </Layout>
}

function ArticleHeader({ article }) {
  return <div>
    <div className='mb-2 text-right'>
      <ShareButtons article={article} url={article.url} />
    </div>
    <PageHeading>{article.name}</PageHeading>
    <Authors authors={article.author}><Metadata article={article} /></Authors>
  </div>
}

export const query = graphql`query($key: String!) {
  site: site {
    siteMetadata {
      url
    }
  }
  article: contentfulSiteArticle(key: {eq: $key}) {
    ...ArticleDetails
    metaDescription
    image {
      fixed: gatsbyImageData(layout: FIXED, width: 1200, height: 627)
    }
  }
  defaultImage: contentfulAsset(title: {eq: "blog-illustration"}) {
    ...ImageContent
    fixed: gatsbyImageData(layout: FIXED, width: 1200, height: 627)
  }
  defaultMetadata: contentfulSiteContentList(key: {eq: "index"}) {
    metaDescription
  }
}`