import React from "react"
import { graphql } from "gatsby"
import { RichText } from "./RichText"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import { faBookReader } from "@fortawesome/pro-regular-svg-icons"
import { slug } from "../gatsby/articles.create"
import { SpaceOut } from "../util/dom"
import { Image } from "./Image"
import { Link } from "./Headings"
import { faFacebookSquare, faLinkedinIn, faPinterest, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { join as cx } from "../styles/styles"

export function ArticleCard({ article, showAuthor, className, padding = 'p-5' }) {
  return <div className={className}>
    <ArticleLink article={article}><Image image={article.image} className='object-cover overflow-hidden' style={{ height: '14rem' }}/></ArticleLink>
    <div className={cx('flex flex-col pt-4', padding)} style={{height: 'calc(100% - 14rem)'}}>
      <div className='flex-initial'>
        <h1 className='text-2xl md:text-3xl mb-0'><ArticleLink article={article}>{article.name}</ArticleLink></h1>
        {showAuthor ?
            <Authors authors={article.author}><Metadata article={article} className='text-sm text-ink-gray'/></Authors>
            : <Metadata article={article} className='pb-4 text-sm text-ink-gray' />}
      </div>
      <div className='flex-grow overflow-hidden'>
        <div className='h-full overflow-x-hidden overflow-y-auto'>
          <RichText body={article.body} displayExcerpt={true} />
        </div>
      </div>
      <div className='flex-initial'>
        <div className='flex py-8'>
          <ArticleLink article={article} className='flex-grow'><Icon icon={faBookReader} /> Continue reading</ArticleLink>
          <ShareButtons article={article} />
        </div>
        <Tags>{article.tags}</Tags>
      </div>
    </div>
  </div>
}

function ArticleLink({ article, className, children}) {
  return <Link href={article.uri} className={className}>{children}</Link>
}

export function Metadata({article, className}) {
  return <div className={className}>
    {article.publishDate}<br/>
    Reading time: {article.fields.readingTimeInMinutes} min
  </div>
}

export function Authors({ authors, children }) {
  const author = Array.isArray(authors) ? authors[0] : authors

  return <div className='flex items-center pb-4'>
    <Image image={author.image} className='rounded-full w-24 mr-4' />
    <div>
      <div className='font-bold'>{author.name}</div>
      {children}
    </div>
  </div>
}

export function Tags({className, children}) {
  if (!children)
    return null

  return <div className={cx(className, 'text-xs')}>
    <SpaceOut>
      {children.map(tag => <Tag tag={tag} />)}
    </SpaceOut>
  </div>
}

function Tag({ tag }) {
  return <li className='mr-1 my-1 px-2 inline-block text-primary box-border border border-primary-100 rounded-full hover:bg-primary hover:border-primary hover:text-page'><Link href={slug('tags', tag.name)} className='text-current hover:text-page'>{tag.description}</Link></li>
}

// Check out https://codebeautify.org/share-link-generator for creating sharing links
export function ShareButtons({ article }) {
  const [title, encodedUrl] = [article.name, article.url].map(encodeURIComponent)

  return <>
    <ShareByMail title={title} url={encodedUrl} />
    <ShareOnLinkedIn title={title} url={encodedUrl} />
    <ShareOnTwitter title={title} url={encodedUrl} />
    <ShareOnFacebook title={title} url={encodedUrl} />
    <ShareOnPinterest title={title} url={encodedUrl}
                      image={article.image?.fixed && encodeURIComponent(article.image.fixed.src)} />
  </>

  function ShareOnLinkedIn({ url, title }) {
    return <Share title='on LinkedIn'
                  action={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=`}>
      <Icon icon={faLinkedinIn} className='text-linkedin' />
    </Share>
  }

  function ShareOnTwitter({ url, title }) {
    return <Share title='on Twitter'
                  action={`https://twitter.com/intent/tweet?url=${url}&text=${title}`}>
      <Icon icon={faTwitter} className='text-twitter' />
    </Share>
  }

  function ShareOnFacebook({ url }) {
    return <Share title='on Facebook'
                  action={`https://www.facebook.com/sharer/sharer.php?u=${url}`}>
      <Icon icon={faFacebookSquare} className='text-facebook' />
    </Share>
  }

  function ShareOnPinterest({ url, title, image }) {
    if (image)
      return <Share title='on Pinterest'
                    action={`https://pinterest.com/pin/create/button/?url=${url}&media=${image}&description=${title}`}>
        <Icon icon={faPinterest} className='text-pinterest' />
      </Share>
    return null
  }

  function ShareByMail({ url, title }) {
    return <Share title='by Mail'
                  action={`mailto:?subject=${encodeURIComponent("Check out this article!")}&body=${title} (${url})`}>
      <Icon icon={faEnvelope} className='text-ink' />
    </Share>
  }

  function Share({ title, action, children }) {
    return <a title={`Share this ${title}`} href={action} className='mx-1'>{children}</a>
  }
}

export function withDefaultImage(image, article) {
  return article ? mapper(article) : mapper

  function mapper(article) {
    return { ...article, image: article.image || image }
  }
}

export function withUrl(siteUrl, article) {
  return article ? mapper(article) : mapper

  function mapper(article) {
    return {...article,
      uri: slug("articles", article.key),
      url: `${siteUrl}${slug("articles", article.key)}`
    }
  }
}

export const query = graphql`
  fragment ArticleDetails on ContentfulSiteArticle {
    __typename
    key
    name
    body {
      raw
      references {
        ... on ContentfulAsset {
          ...ImageContent
        }
        ... on ContentfulEntry {
          contentful_id
        }
      }
    }
    author {
      name
      image {
        ...ImageContent
      }
    }
    tags {
      name
      description
    }
    image {
      ...ImageContent
    }
    fields {
      readingTimeInMinutes
    }
    publishDate(formatString: "MMMM D, YYYY")
  }
`